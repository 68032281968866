export interface Locale {
    locale: string,
    code: string,
    text: string,
    flag: string
}

export const locales: Locale[] = [{
    locale: 'de-DE',
    code: 'toolbar.locale.germanCode',
    text: 'toolbar.locale.germanText',
    flag: require('@/assets/de.png')
}, {
    locale: 'en-GB',
    code: 'toolbar.locale.englishCode',
    text: 'toolbar.locale.englishText',
    flag: require('@/assets/gb.png')
}];

export function findLocale(locale: string | null | undefined): Locale | undefined {
    if(!locale) {
        return undefined;
    }

    let result = locales.find(i => i.locale === locale);
    if(result) {
        return result;
    }

    result = locales.find(i => i.locale.substring(0, 2).toLowerCase() == locale.substring(0, 2).toLowerCase());
    
    return result;
}

export function getLocalesString(separator?: string) {
    return locales.map(i => i.locale).join(separator);
}

export function getShortLocaleCode(locale?: string): string | undefined {
    if(!locale) return locale;
    return locale.substring(0, 2).toLowerCase();
}